import { Button, Stack, Box, Typography, TextField } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

import PasswordInput from "components/Atoms/Input/PaswordInput";
export default function DeleteAccountForm({ formik }) {
  const { red } = useThemePalette();

  return (
    <form onSubmit={formik?.handleSubmit} style={{ height: "100%" }}>
      <Stack
        sx={{
          boxShadow: { xs: "none", md: "0px 0px 24px 12px rgba(194, 194, 194, 0.10)" },
          height: "100%",
          py: { xs: 0, md: 5 },
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", md: "90%" },
            mx: "auto",
            height: "100%",
          }}
          gap={{ xs: 1, md: 5 }}
        >
          <Stack alignItems={{ xs: "start", md: "center" }} gap={1}>
            <Box
              component="img"
              src={icons.DeleteAccount}
              sx={{
                width: { xs: "112px", md: "150px" },
                height: { xs: "112px", md: "150px" },
                fontSize: "25px",
              }}
            />
            <Typography
              sx={{ fontSize: { xs: "20px", md: "32px" }, fontFamily: "Jost", fontWeight: "500" }}
            >
              Delete account
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "24px" },
                lineHeight: { xs: "18px", md: "31px" },
                textAlign: { xs: "start", md: "center" },
                fontWeight: 400,
              }}
            >
              By deleting this account you will lose all your <br />
              information you will not able to use it again this <br /> can&apos;t be undone
            </Typography>
          </Stack>
          <Stack
            gap={2}
            sx={{
              ".MuiInputLabel-root": { fontSize: { xs: "16px", md: "18px" } },
              ".MuiInput-input": { color: "#000" },
            }}
          >
            <Stack>
              <TextField
                label="Email"
                variant="standard"
                sx={{ py: { xs: 0, md: 1 } }}
                name="email"
                onChange={formik.handleChange}
                value={formik?.values["email"]}
              />
              <Typography sx={{ color: red }}>{formik.errors["email"]}</Typography>
            </Stack>
            <PasswordInput
              value={formik?.values["password"]}
              onChange={formik.handleChange}
              error={formik.errors["password"]}
            />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            sx={{ bgcolor: red, "&:hover": { bgcolor: red }, width: "100%", mt: "auto" }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
