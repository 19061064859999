import { Stack } from "@mui/material";
import Title from "components/Atoms/Text/Title";
import OurClientCard from "components/Molecules/Cards/OurClientCard";

export default function OurClients({ ourClients }) {
  return (
    <Stack alignItems="center" gap={4}>
      <Title title="Our Clients" />
      <Stack sx={{ width: "100%" }} direction="row" flexWrap="wrap" gap={1}>
        {ourClients?.map((client, i) => (
          <OurClientCard client={client} key={i} />
        ))}
      </Stack>
    </Stack>
  );
}
