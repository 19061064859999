import { Button, Link, Stack, Typography, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { icons } from "assets/AssetHelper";

export default function OurWorkCard({ project }) {
  return (
    <Stack
      sx={{
        bgcolor: project?.bgcolor,
        color: "#fff",
        p: 3,
        py: 5,
        borderRadius: "6px",
      }}
      direction="row"
    >
      {/* left stack */}
      <Stack gap={5} sx={{ width: "100%", pt: 1 }}>
        <Stack>
          <Typography
            sx={{
              textTransform: "upperCase",
              fontFamily: "Open Sans",
              fontWeight: 800,
              fontSize: "21px",
            }}
          >
            {project?.name}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography sx={{ fontFamily: "Open Sans", fontWeight: 600, fontSize: "12px" }}>
            Technologies
          </Typography>
          <Stack>
            {project?.technologies.map((tech, i) => (
              <Typography
                sx={{ fontFamily: "Open Sans", color: "#E8E8E8", fontWeight: 400, fontSize: "9px" }}
                key={i}
              >
                {tech}s
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>Languages</Typography>
          <Stack gap={0.6}>
            {project?.languages.map((lang, i) => (
              <Typography
                sx={{ fontFamily: "Open Sans", color: "#E8E8E8", fontWeight: 400, fontSize: "9px" }}
                key={i}
              >
                {lang}
              </Typography>
            ))}
          </Stack>
        </Stack>

        <Stack sx={{ mt: "auto" }}>
          <Link target="_blank" href="">
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              sx={{ color: "#fff", p: 0, fontsize: "9px", border: "none" }}
            >
              View Porject
            </Button>
          </Link>
        </Stack>
      </Stack>
      {/* right stack */}
      <Stack sx={{ p: 0, width: "100%" }}>
        <Box component="img" src={project?.img} sx={{ width: "105%", display: "flex" }} />
        <Stack sx={{ width: "50%", mx: "auto" }} direction="row" gap={5}>
          {project?.stores?.map(
            (store, i) =>
              store && (
                <Link key={i} href="" target="_blank">
                  <Box
                    component="img"
                    src={store == "apple" ? icons.Apple : icons?.Android}
                    sx={{ width: "120%" }}
                  />
                </Link>
              )
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
