import { combineReducers } from "redux";
import modalReducer from "./modules/modals/Reducer";
import { authReducer } from "./modules/Auth/Reducer";

const rootReducer = combineReducers({
  modal: modalReducer,
  auth: authReducer,
});

export default rootReducer;
