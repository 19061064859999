import { icons, images } from "assets/AssetHelper";
import AboutDeveloperTemplate from "templates/aboutDeveloper/AboutDeveloper.Template";

export default function AboutDeveloperPage() {
  const introData = {
    text: "We are a web and mobile app development company, headquartered in Alexandria, Egypt. We have developed an extensive, rich and interactive experience mobile app development, mobile games development and enterprise web apps since 2011.",
    link: "https://inovaeg.com/",
  };

  const ourWork = [
    {
      name: "alo moves",
      technologies: ["Social Networking"],
      languages: ["Swift", "Java"],
      stores: ["apple", "android"],
      img: images.AloMoves,
      bgcolor: "#1C1C1C",
    },
    {
      name: "alo moves",
      technologies: ["Social Networking"],
      languages: ["Swift", "Java"],
      stores: ["apple", "android"],
      img: images.AloMoves,
      bgcolor: "#1C1C1C",
    },
  ];

  const ourClients = [
    {
      img: images.Honeywell,
    },
    {
      img: images.AlJarhyBank,
    },
    {
      img: images.Honeywell,
    },
  ];

  const footer = {
    socialMedia: [
      { icon: icons?.Facebook, link: "" },
      { icon: icons?.Twitter, link: "" },
      { icon: icons?.Instagram, link: "" },
      { icon: icons?.LinkedIn, link: "" },
    ],
    contactInfo: [
      {
        icon: icons.Location,
        info: ["9 Abd El-Qader Ateya Street, Fleming Qism El-Raml, Alexandria, Egypt"],
      },
      {
        icon: icons.Phone,
        info: ["(+2) 03 5858919", "(+2) 01025065179"],
      },
      {
        icon: icons.Whatsapp,
        info: ["(+2) 03 5858919"],
      },
      {
        icon: icons.Mail,
        info: ["sales@inovaeg.com"],
      },
    ],
  };

  return (
    <AboutDeveloperTemplate
      introData={introData}
      ourWork={ourWork}
      ourClients={ourClients}
      footer={footer}
    />
  );
}
