import { Stack } from "@mui/material";
import Footer from "components/Organisms/aboutDeveloper/Footer";
import Intro from "components/Organisms/aboutDeveloper/Intro";
import OurClients from "components/Organisms/aboutDeveloper/OurClients";
import OurWork from "components/Organisms/aboutDeveloper/OurWork";

export default function AboutDeveloperTemplate({ introData, ourWork, ourClients, footer }) {
  return (
    <Stack gap={5} p={2}>
      {/* intro */}
      <Intro introData={introData} />
      {/* our work */}
      <OurWork ourWork={ourWork} />
      {/* our clients */}
      <OurClients ourClients={ourClients} />
      {/* footer */}
      <Footer footer={footer} />
    </Stack>
  );
}
