import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {},
  theme_palette: {
    red: "#DF2929",
    gray: "#8E8E93",
    offWhite: "#F5F5F5",
    sectionTitle: "#CB2127",
  },
  overrides: {
    MuiPaper: {
      root: {
        outline: "none",
      },
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application !
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "12px 97px",
          fontFamily: "Nunito",
          textTransform: "none",
          fontSize: { xs: "16px", md: "18px" },
          fontWeight: "700",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
});
