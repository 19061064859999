import { Stack, Box, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";

export default function Footer({ footer }) {
  return (
    <Stack gap={6}>
      <Stack alignItems="center" gap={1}>
        <Box component="img" src={icons.INOVA} sx={{ width: "43%" }} />
        <Stack direction="row" gap={0.8}>
          {footer.socialMedia?.map((platform, i) => (
            <Box
              component="img"
              src={platform.icon}
              sx={{ width: "30px", height: "20px" }}
              key={i}
            />
          ))}
        </Stack>
      </Stack>
      {/* contact & location */}
      <Stack gap={3}>
        {footer?.contactInfo.map((data, i) => (
          <Stack key={i} gap={1}>
            <Box component="img" src={data.icon} sx={{ maxWidth: "18.5px" }} />
            <Stack>
              {data?.info?.map((info, i) => (
                <Typography
                  sx={{ color: "#221F1F", fontWeight: 600, lineHeight: "21.79px" }}
                  key={i}
                >
                  {info}
                </Typography>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
