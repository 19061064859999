import { Stack } from "@mui/material";
import Logo from "components/Atoms/Logo/Logo";
import DeleteAccountForm from "components/Molecules/Form/DeleteAccountForm";

export default function DeleteAccountTemplate({ formik }) {
  return (
    <Stack
      sx={{
        width: "95%",
        mx: "auto",
        height: { xs: "95vh", md: "auto" },
      }}
    >
      <Stack sx={{ alignItems: { xs: "end", md: "start" }, pt: 4 }}>
        <Logo />
      </Stack>
      <Stack
        sx={{
          width: { xs: "98%", md: "60%", lg: "50%", xl: "40%" },
          mx: "auto",
          height: "100%",
        }}
      >
        <DeleteAccountForm formik={formik} />
      </Stack>
    </Stack>
  );
}
