import { Stack } from "@mui/material";
import Title from "components/Atoms/Text/Title";
import OurWorkCard from "components/Molecules/Cards/OurWorkCard";

export default function OurWork({ ourWork }) {
  return (
    <Stack alignItems="center" gap={4}>
      <Title title="Our Work" />
      <Stack sx={{ width: "100%" }} gap={3}>
        {ourWork?.map((project, i) => (
          <OurWorkCard project={project} key={i} />
        ))}
      </Stack>
    </Stack>
  );
}
