import Logo from "./images/logo.png";
import DeleteAccount from "./icons/DeleteAccount.png";
import Exclamationmark from "./icons/Exclamationmark.svg";
import Success from "./icons/Success.svg";
import NotFound from "./icons/NotFound.svg";
import INOVA from "./icons/INOVA.png";
import Apple from "./icons/Apple.svg";
import Android from "./icons/Android.svg";
import Facebook from "./icons/Facebook.svg";
import Twitter from "./icons/Twitter.svg";
import Instagram from "./icons/Instagram.svg";
import LinkedIn from "./icons/LinkedIn.svg";
import Location from "./icons/Location.svg";
import Whatsapp from "./icons/Whatsapp.svg";
import Mail from "./icons/Mail.svg";
import Phone from "./icons/Phone.svg";
//work
import AloMoves from "./images/AloMoves.png";
//clients
import Honeywell from "./images/Honeywell.png";
import AlJarhyBank from "./images/Al-Jarhy-Bank.png";

export const dummy = {};

export const images = {
  Logo,
  AloMoves,
  Honeywell,
  AlJarhyBank,
};

export const icons = {
  DeleteAccount,
  Exclamationmark,
  Success,
  NotFound,
  INOVA,
  Apple,
  Android,
  LinkedIn,
  Instagram,
  Twitter,
  Facebook,
  Location,
  Whatsapp,
  Mail,
  Phone,
};
