import { Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";

export default function Title({ title }) {
  const { sectionTitle } = useThemePalette();

  return (
    <Typography
      sx={{
        color: sectionTitle,
        fontFamily: "Open Sans",
        textTransform: "upperCase",
        fontWeight: 700,
        fontSize: "20px",
      }}
    >
      {title}
    </Typography>
  );
}
