import { Box, Stack } from "@mui/material";

export default function OurClientCard({ client }) {
  return (
    <Stack
      sx={{
        p: 2,
        width: "47%",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
        boxSizing: "border-box",
        minHeight: "90px",
      }}
      justifyContent="center"
    >
      <Box component="img" src={client.img} />
    </Stack>
  );
}
