import { Box, Link, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";

export default function Intro({ introData }) {
  return (
    <Stack alignItems="center" gap={2}>
      <Box component="img" src={icons.INOVA} sx={{ width: "35%" }} />
      <Stack>
        <Typography
          sx={{
            textAlign: "center",
            lineHeight: "17px",
            fontSize: "14px",
            fontFamily: "Cabin",
            color: "#7C7C7C",
            fontWeight: "400",
          }}
        >
          {introData?.text}
        </Typography>
      </Stack>
      <Link
        href={introData?.link}
        target="_blank"
        sx={{ textDecoration: "underline", color: "#1A1919", fontWeight: "400" }}
      >
        {introData?.link}
      </Link>
    </Stack>
  );
}
